
import Vue from "vue";
import { mapGetters } from "vuex";
import RULES from "@/mixins/rules";
import UserService from "@/services/UserService";
import { VForm } from "@/types/formvalidate";
import {Vex} from "@/plugins/vex";
import {CatalogoService} from "@/services/CatalogoService";
import { Usuario } from "@/models/Usuario";
//import { jsPDF } from "jspdf";
// @ts-ignore
export default Vue.extend({
    name: "FormUpdate",
    components: {

    },
    mixins: [RULES],
    data() {
    return {
        serviceLineas: new CatalogoService('catalogos/lineas_navieras'),
        terminated: false,
        formDefault: new Usuario(),
        formUser : new Usuario(),
        userName: '',
        Lineas:[],
        Buques:[],
    };
    },
    computed: {
        Usuario() {
            return Usuario;
        },
        form(): VForm {
            return this.$refs.form as VForm;
        },
        ...mapGetters({
            usuario: 'usuario', modules: 'modules' , email: 'email' , perfil: 'perfil', id:'id', status: 'status', rfc: 'rfc', mini: "sidebar", role:'role',
        })
    },
    // @ts-ignore
    mounted() {
        if(this.role === "operativoMunicipio" ){
            this.$router.push({name: 'Full'});
        }
        if(this.role === "hotelero" || this.role === "embarcacion") {
            this.formUser.rfc = this.rfc;
            this.formUser.email = this.email;
            this.formUser.status = this.status;
            this.formUser.id = this.id;
            this.getData();
            this.getLineasNavieras();
        }
    },
    methods: {
        handleFileUploadSuccess(data: any) {
            // type FormUserKey = keyof typeof this.formUser;
            const typeFile = data.typeFile;
            const message = data.message;
            //@ts-ignore
            this.formUser[typeFile] = null;
            this.$toast.success(message);
        },
        handleFileUploadError (data: any){
            const message = data.message;
            this.$toast.error(message);
        },
        async getLineasNavieras(){
          let {data} = await this.serviceLineas.getAll();
          this.Lineas = data;
        },
        async getData(){
            await UserService.getUser(this.id).then(
                (response) => {
                    if(response.data.success){
                        this.formDefault = response.data.data.data;
                        let user = response.data.data.data;
                        // @ts-ignore
                         this.formUser.grado_academico = this.formDefault.grado_academico;
                        // @ts-ignore
                        this.formUser.nombre = this.formDefault.nombre;
                        this.userName = this.formDefault.nombre;
                        if (this.userName == null){
                          this.$toast.warning("Por favor, modifique el nombre del contribuyente en el apartado correspondiente para poder registrar una solicitud.");
                        }
                        console.log(this.userName)
                        // @ts-ignore
                        this.formUser.primer_apellido = this.formDefault.primer_apellido;
                        // @ts-ignore
                        this.formUser.segundo_apellido = this.formDefault.segundo_apellido;
                        // @ts-ignore
                        this.formUser.telefono = this.formDefault.telefono;
                        // @ts-ignore
                        this.formUser.motivo_rechazo = this.formDefault.motivo_rechazo;
                        // @ts-ignore
                        this.formUser.patente = this.formDefault.documento;
                        if(this.role === 'hotelero'){
                          //@ts-ignore
                          this.formUser.nombre_establecimineto = this.formDefault.establecimiento.nombre;
                          //@ts-ignore
                          this.formUser.direccion_establecimineto = this.formDefault.establecimiento.calle;
                          //@ts-ignore
                          this.formUser.localidad_establecimineto = this.formDefault.establecimiento.localidad;
                          //@ts-ignore
                          this.formUser.municipio_establecimineto = this.formDefault.establecimiento.municipio;
                        }else if(this.role === 'embarcacion'){
                          let puerto = '';
                          let linea = '';
                          //@ts-ignore
                          user.user_puerto.forEach(item=>{
                            //@ts-ignore
                            puerto += item.puerto.puerto + ' , ';
                            //@ts-ignore
                            item.puerto.linea_nav.forEach(item2 => {
                              //@ts-ignore
                              linea += item2.nombre + ',';
                            });
                          });
                          //@ts-ignore
                          this.formUser.linea_naviera = puerto;
                          //@ts-ignore
                          this.formUser.direccion_naviera = linea;
                        }
                        console.log(this.formDefault);
                        if(this.formUser.status != 1 && this.formUser.status != 4){
                            this.terminated = true;
                        }
                    }
                }
            );
        },
        // @ts-ignore
        async update(){
            if(this.form.validate()){
                this.formUser.id = this.id;
                await UserService.updateNotario(this.formUser).then(
                    (response) => {
                        if (response.data.success) {
                            this.$store.dispatch("setSolicitud", response.data.data.estatus_id);
                            this.$toast.success(response.data.message);
                        } else {
                            this.$toast.error(response.data.message);
                        }
                    }
                );
                await this.$store.dispatch("updateUser",
                this.formUser.nombre + ' ' +
                this.formUser.primer_apellido + ' ' +
                this.formUser.segundo_apellido );
                location.reload();
            }
        },
        async enviarRevision(){
            await UserService.changeStatus({ usuario: this.id }).then(
                (response) => {
                    if(response.data.success){
                        this.terminated = true;
                        this.$store.dispatch("setSolicitud", response.data.data.estatus_id);
                        this.$toast.success('Su solicitud ha sido enviada exitosamente');
                    } else {
                        this.$toast.error('Ocurrio un error al enviar su solicitud, intente de nuevo');
                    }

                }
            );
          setTimeout(() => {
            location.reload();
          }, 3000);
        },
      traerBuques(){
          var idLinea = this.formUser.linea_naviera;
          if(idLinea === null){
            this.formUser.direccion_naviera = '';
            this.Buques = [];
          }else{
            this.Lineas.forEach(
                (item) => {
                  //@ts-ignore
                  if(item.id === idLinea){
                    //@ts-ignore
                    this.formUser.direccion_naviera = item.domicilio;
                    //@ts-ignore
                    this.Buques = item.buques;
                  }
                }
            );

          }
      }
    },
});
