import {DefaultService} from "@/services/DefaultService";
import API from "@/plugins/axios";

class SimService extends DefaultService {

  constructor() {
    super('notarias');
  }
  getPagoPredial(clave_catastral: string): Promise<any>{
    return API.get(`notarias/getPagoPredial/${clave_catastral}`);
  }

  getAllPagos(data: any): Promise<any>{
    return API.post(`SIM/getAllPagos`,data);
  }
}

export default new SimService();