
import Vue from 'vue';
import {VForm} from "@/types/formvalidate";
import axios from "@/plugins/axios";
import rules from "@/mixins/rules";
import DiaService from "@/services/DiaService";

export default Vue.extend({
    name: 'Calendario',
    mixins: [rules],
    components: {},
    data: () => ({
        focus: '',
        type: 'month',
        typeToLabel: {
            month: 'Mes',
            week: 'Semana',
            day: 'Dia',
            '4day': '4 Dias',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        colors: ['', 'grey darken-1', 'deep-purple'],
        names: ['', 'Día inhabil','Fin de semana - inhabil'],
        dias: [],
        dialog: false,
        dialogpdf: false,
        formDia: {
            dia: '',
            descripcion: '',
            estado: ''
        },
        estado: [
            {
            id: 0,
            name: "Día hábil"
            },
            {
            id: 1,
            name: "Día inhábil"
            }
        ],
        select: { id: 0, name: "Día habil" },
    }),
    mounted() {
        if(this.$refs.calendar){
            // @ts-ignore
            this.$refs.calendar.checkChange();
        }
    },
    computed: {
        form(): VForm {
            return this.$refs.setdia as VForm
        },
    },
    watch: {},
    methods: {
        
        // @ts-ignore
        getEventColor (event) {
            return event.color
        },
        setToday () {
            this.focus = ''
        },
        prev () {
            // @ts-ignore
            this.$refs.calendar.prev()
        },
        next () {
            // @ts-ignore
            this.$refs.calendar.next()
        },
        // @ts-ignore
        showEventDay ({ date }) {
            this.dialog = true;
            this.formDia.dia = date;
            this.formDia.descripcion = '';
        },
        async updateRange () {
            // @ts-ignore
            const events = []

            // Ejemplo de uso
            const startDate = '2024-01-01';
            const endDate = '2024-12-31';
            const start = new Date(startDate);
            const end = new Date(endDate);
            const dates = [];

            let currentDate = start;
            while (currentDate <= end) {
                dates.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }

            const fechas = dates.map(date => date.toISOString().split('T')[0]);
            const tam = fechas.length;
            for (let i = 0; i < tam; i++) {
                if(new Date(fechas[i]).getDay() == 5 || new Date(fechas[i]).getDay() == 6){
                    events.push({
                        name: this.names[2],
                        start: fechas[i],
                        color: this.colors[2]
                    })
                }
            }


            await DiaService.getDias().then(
            (response) => {
                const datos = response.data.data;
                const longitudDatos = datos.length;
                for (let i = 0; i < longitudDatos; i++){
                events.push({
                    name: datos[i].descripcion + ' - ' + this.names[datos[i].estado],
                    start: datos[i].dia,
                    color: this.colors[datos[i].estado]
                })
                }
            }
            );
            // @ts-ignore
            this.events = events;
        },

        async almacenarDia () {
            if(this.form.validate()){
                let {data} = await DiaService.setDia(this.formDia);
                if(data.success){
                    this.updateRange();
                    this.dialog = false;
                    this.$toast.success(data.message);
                }else{
                    this.$toast.error(data.message);
                }
            }
        }
    }
})
