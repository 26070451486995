import Json from '@/types/json'
interface _Calculo {
    id: number | null;
    pasajeros_gravados: number | null;
    exentos: number | null;
    tipo_declaracion: string;
    total_pasajeros: number | null;
    tarifa: number | null;
    derecho: number | null;
    total_pagar:number | null;
    numHabitaciones: number | null;
    numHabitacionesOcupadas: number | null;
    porcentaje_Ocupacion:number | null;
    derecho_pagar:number | null;
    uma:number;
}

class Calculo implements _Calculo{
    id: number = 0;
    pasajeros_gravados: number = 0;
    exentos: number = 0;
    tipo_declaracion: string = '';
    total_pasajeros: number = 0;
    tarifa: number = 0;
    derecho: number = 0;
    total_pagar: number = 0;
    numHabitaciones: number =0;
    numHabitacionesOcupadas: number =0;
    porcentaje_Ocupacion: number =0;
    derecho_pagar: number =0;
    uma: number = 0;
    setData(data: Json){
    }
}

export {Calculo,_Calculo}