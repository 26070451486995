
import Vue from "vue";
import RULES from "@/mixins/rules";
import SatService from "@/services/SatService";
import {mapGetters} from "vuex";
import any = jasmine.any;

export default Vue.extend({
  name: 'FormSeal',
  mixins: [RULES],
  props: ['select','ruta'],
  data: () => ({
    dialog: false,
    showPassword: false,
    latitude: '',
    longitude: '',
    showPasswordUser: false,
    formSat: {
      key: '',
      cer: '',
      password: '',
      passwordUser: ''
    },
  }),
  mounted() {
    this.getPosition()
  },
  computed: {
    ...mapGetters({
      usuario: 'usuario', modules: 'modules', email: 'email', status: 'status', role:'role'
    })
  },
  methods: {
    close() {
      this.$router.push({name: 'isabi'}).catch(() => {
      });
    },
    getPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) =>{
          // @ts-ignore
          this.latitude = position.coords['latitude'];
          // @ts-ignore
          this.longitude = position.coords['longitude'];

        });
      }
    },
    async save() {
      let data = null;

      const formData = new FormData();

        formData.append('document-cer', this.formSat.cer);
        formData.append('document-key', this.formSat.key);
        formData.append('password', this.formSat.password);
        formData.append('role', this.role);
        formData.append('latitude', this.latitude);
        formData.append('longitude', this.longitude);
        formData.append('password-user', this.formSat.passwordUser);

        // @ts-ignore
        this.select.forEach((value) => {
          formData.append("selected[]", value.id);
        });

        data = await SatService.create(formData)

      if (data['success']) {
        this.$toast.success(data['message'], {
          // @ts-ignore
          duration: 3000 // Duración del mensaje en milisegundos (5 segundos)
        });
        setTimeout(() => {
          window.location.replace('/admin/solicitudesDidsa');
        }, 3000); // Esperar 5 segundos antes de redirigir
      } else {
        this.formSat.passwordUser = '';
        this.$toast.warning(data['message'], {
          // @ts-ignore
          duration: 3000 // Duración del mensaje en milisegundos (5 segundos)
        });
      }
    }
  }
});
