
import Vue from 'vue';
import {mapGetters} from "vuex";
import rules from "@/mixins/rules";
import SolicitudService from '@/services/SolicitudService';
import {VForm} from '@/types/formvalidate';
// @ts-ignore
import EventBus from "@/plugins/event-bus";
import SimService from "@/services/SimService";
import {CatalogoService} from "@/services/CatalogoService";
import DiasService from "@/services/DiaService";
import CustomFileIsabi from "@/components/Common/CustomFileIsabi.vue";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import moment from 'moment';
import numeric from '@/directives/numeric';
import {parseInt} from "lodash";

export default Vue.extend({
  name: 'FormDidsaCReadOnly',
  mixins: [rules],
  components: {

  },
  data: () => ({
    serviceUma: new CatalogoService('catalogos/uma'),
    serviceNavieras: new CatalogoService('catalogos/lineas_navieras'),
    menu: false,
    valor_uma:0,
    formSolicitud:{
      rfc:'',
      representante: '',
      linea_id:'',
      buque_id: '',
      fecha_arribo: '',
      email: '',
      mes: '',
      anio:'',
      calle:'',
      numExterior: '',
      numInterior: '',
      codigoPostal: '',
      colonia:'',
      localidad:'',
      municipio:'',
      estado: '',
      pasajeros_gravados: 0,
      exentos: 0,
      tipo_declaracion: '',
      total_pasajeros: 0,
      tarifa: 0,
      derecho: 0,
      total_pagar:0,
      operacion:2,
      estatus_id:0,
      motivo_rechazo:'',
      idContribuyente:0,
    },
    textDerecho: '0',
    textPagar: '0',
    listBuques: [],
    listNavieras:[],
    datos: [],
    didsaId:'',
    editar:false,
    cambio:false,

    formRechazo: {
      id: '',
      motivo_rechazo: '',
    },
    dialog: false,
  }),
  watch: {

  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
    formRe(): VForm {
      return this.$refs.formRechazo as VForm;
    },
    ...mapGetters({
      usuario: 'usuario', id: 'id', mini: 'sidebar', role:'role'
    }),
  },
  mounted() {
    this.didsaId = this.$route.params.didsaId;
    this.editar = Boolean(this.$route.params.editar);
    this.getData();
  },
  methods: {
    async getData() {
      await SolicitudService.getById(this.didsaId).then(
          (response) => {
            if(response.status === 200){
              var datos = response.data;
              this.formSolicitud.rfc = datos.contribuyente.rfc;
              this.formSolicitud.representante = datos.representante;
              this.formSolicitud.linea_id = datos.buque.lineaNaviera_id;
              this.formSolicitud.buque_id = datos.buque.id;
              this.formSolicitud.fecha_arribo = datos.fecha_arribo;
              this.formSolicitud.email = datos.contribuyente.email;
              this.formSolicitud.mes = datos.mes_arribo;
              this.formSolicitud.anio = datos.anio_arribo;
              this.formSolicitud.calle = datos.buque.linea_naviera.calle;
              this.formSolicitud.numExterior = datos.buque.linea_naviera.numero_exterior;
              this.formSolicitud.numInterior = datos.buque.linea_naviera.numero_interior;
              this.formSolicitud.codigoPostal = datos.buque.linea_naviera.codigo_postal;
              this.formSolicitud.colonia = datos.buque.linea_naviera.colonia;
              this.formSolicitud.localidad = datos.buque.linea_naviera.localidad;
              this.formSolicitud.municipio = datos.buque.linea_naviera.municipio;
              this.formSolicitud.estado = datos.buque.linea_naviera.entidad;
              this.formSolicitud.pasajeros_gravados = datos.calculo.numPasajeros;
              this.formSolicitud.exentos = datos.calculo.numExentos;
              this.formSolicitud.tipo_declaracion = datos.calculo.tipo_declaracion;
              this.formSolicitud.total_pasajeros = datos.calculo.total_pasajeros;
              this.formSolicitud.tarifa = datos.calculo.tarifa;
              this.formSolicitud.derecho = datos.calculo.derecho;
              this.textDerecho = new Intl.NumberFormat().format(this.formSolicitud.derecho);
              this.formSolicitud.total_pagar = datos.calculo.derecho_pagar;
              this.formSolicitud.estatus_id = response.data.estatus_id;
              this.textPagar = new Intl.NumberFormat().format(this.formSolicitud.total_pagar);
              this.formSolicitud.idContribuyente = datos.contribuyente.id;
              this.getNaviera(datos.contribuyente.id);
            }
          }
      );
    },
    //@ts-ignore
    async getNaviera(datos) {
      let dato = {id: this.formSolicitud.idContribuyente}
      const [navieras] = await Promise.all([
        this.serviceNavieras.getAll(dato),
      ]);
      //@ts-ignore
      navieras.data.forEach(item => {
        //@ts-ignore
        item.puerto.linea_nav.forEach(item => {
          //@ts-ignore
          this.$set(this.listNavieras, item.id , item);
          //@ts-ignore
          this.$set(this.listBuques, item.id, item.buques);
        })
      });
      console.log(this.listNavieras);
    },
    setdatos() {
      //@ts-ignore
      this.datos = this.listNavieras.filter(dt => dt.id == this.formSolicitud.linea_id);
      //@ts-ignore
      this.formSolicitud.calle = this.datos[0].calle;
      //@ts-ignore
      this.formSolicitud.numExterior = this.datos[0].numero_exterior;
      //@ts-ignore
      this.formSolicitud.numInterior = this.datos[0].numero_interior;
      //@ts-ignore
      this.formSolicitud.codigoPostal = this.datos[0].codigo_postal;
      //@ts-ignore
      this.formSolicitud.colonia = this.datos[0].colonia;
      //@ts-ignore
      this.formSolicitud.localidad = this.datos[0].localidad;
      //@ts-ignore
      this.formSolicitud.municipio = this.datos[0].municipio;
      //@ts-ignore
      this.formSolicitud.estado = this.datos[0].entidad;
      console.log(this.datos);
    },
    async calculo() {
      if (this.form.validate()) {
        if (this.formSolicitud.fecha_arribo == '') {
          this.$toast.warning("Debe seleccionar la fecha de arribo");
          return;
        }
        if (this.formSolicitud.pasajeros_gravados == 0) {
          this.$toast.warning("Debe proporcionar el numero de pasajeros gravados");
          return;
        }
        this.formSolicitud.total_pasajeros = parseInt(this.formSolicitud.pasajeros_gravados) + parseInt(this.formSolicitud.exentos);
        const fecha1 = new Date(this.formSolicitud.fecha_arribo)
        //@ts-ignore
        var fechaActual = fecha1.getFullYear();
        let dato = {search: fechaActual}
        const [umaRes] = await Promise.all([
          this.serviceUma.getAll(dato),
        ]);
        //Umas
        if (umaRes.data.length > 0) {
          this.valor_uma = umaRes.data[0].valor_diario;
        }
        //no hay uma vigente
        else {
          this.$toast.warning("No hay registro de uma vigente");
        }

        var uma = this.valor_uma * .15;
        //@ts-ignore
        this.formSolicitud.tarifa = uma.toFixed(2);
        this.formSolicitud.derecho = this.formSolicitud.tarifa * this.formSolicitud.total_pasajeros;
        this.formSolicitud.total_pagar = this.formSolicitud.derecho;
        this.cambio = false;
      }else{
        this.$toast.warning("Faltan datos por rellenar");
        return;
      }

    },
    // @ts-ignore
    async Validar() {
      await SolicitudService.ValidarSoli(parseInt(this.didsaId)).then(
          (response) => {
            if (response.data.success) {
              this.$toast.success(response.data.message);
            } else {
              this.$toast.error(response.data.message);
            }
            setTimeout(() => {
              location.reload();
            }, 2000)
          }
      );
    },
    // @ts-ignore
    async RechazarDialog() {
      this.formRechazo.id = this.didsaId;
      this.dialog = true;
    },
    // @ts-ignore
    async RechazarEnvio() {
      if (this.formRe.validate()) {
        await SolicitudService.RechazarSoli(this.formRechazo).then(
            (response) => {
              if (response.data.success) {
                this.dialog = false;
                this.$toast.success(response.data.message);
              } else {
                this.$toast.error(response.data.message);
              }
              setTimeout(() => {
                location.reload();
              }, 2000)
            }
        );
      }
    },
    async update() {
      if (this.form.validate() && !this.cambio) {
        let data_pre = null;
        data_pre = await SolicitudService.update(this.didsaId, this.formSolicitud);
        let {data} = data_pre;
        if (data.success) {
          this.$toast.success(data.message);
          this.$router.push({name: 'didsac'}).catch(() => {
          });
        } else {
          this.$toast.warning(data.message);
        }
      } else {
        this.$toast.warning("Faltan datos por rellenar");
        return;
      }
    },
    close() {
      if(this.role == 'directoraMunicipio'){
        this.$router.push({name: 'solicitudesDidsa'}).catch(() => {
        });
      }else {
        this.$router.push({name: 'didsac'}).catch(() => {
        });
      }
    },
  },
})
