import Json from '@/types/json'
import { Calculo } from "./Calculo";
import {Usuario} from "@/models/Usuario";

interface _FileDocument {
    code: string,
    file: string,
    binary: string,
    data: any  
}

class FileDocument implements _FileDocument {
    code: string
    file: string
    binary: string
    data: any  

    constructor() {
        this.code = '';
        this.file = '';
        this.binary = '';
        this.data = null;
    }
}

interface _Solicitud {
    id: number | null;
    user_id: number;
    licencia: string | null;
    nombre_persona: string | null;
    nombre_establecimiento: string | null;
    periodo: any | null;
    rfc: string | null;
    representante: string;
    establecimiento_id :number | null;
    id_establecimiento_api :number | null;
    linea_id:number | null;
    buque_id: number | null;
    fecha_arribo: string;
    email: string;
    mes: string;
    anio:string;
    calle:string;
    numExterior: string;
    numInterior: string;
    codigoPostal: number ;
    colonia:string;
    localidad:string;
    municipio:string;
    estado: string;
    operacion: number | null;
    calculo: Calculo | null;
    estatus_id: number | null;
    documento_binary: any | null;
    documento: FileDocument;
    motivo_rechazo: string;
}

class Solicitud implements _Solicitud{
    id: number = 0;
    licencia: string = '';
    nombre_persona: string = '';
    nombre_establecimiento: string = '';
    user_id: number = 0;
    periodo:  any;
    rfc: string = '';
    representante: string = '';
    establecimiento_id: number = 0;
    id_establecimiento_api= 0;
    linea_id: number = 0;
    buque_id: number = 0;
    fecha_arribo: string = '';
    email: string = '';
    mes: string = '';
    anio:string = '';
    calle:string = '';
    numExterior: string = '';
    numInterior: string = '';
    codigoPostal: number  = 0;
    colonia:string = '';
    localidad:string = '';
    municipio:string = '';
    estado: string = '';
    operacion: number = 0;
    calculo = new Calculo();
    estatus_id: number = 0;
    documento_binary = '';
    documento = new FileDocument()
    motivo_rechazo: string = '';
    setData(data: Json){

    }
}

export {Solicitud,_Solicitud}