
import Vue from 'vue';
import {mapGetters} from "vuex";
import SolicitudService from '@/services/SolicitudService';
export default Vue.extend({
  name: 'NotarioDashboard',
  data() {
    return {
      solicitudesTotal: 0,
      solicitudesPendientes: 0,
      solicitudesCompletado: 0,
      solicitudesRechazadas: 0,
      constanciasTotal: 0,
      constanciasEnviadas: 0,
      greeting: "",
      chartConstanciasByStatus: {
        type: "doughnut",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          title: {
            display: true,
            text: "Distribución de Constancias por estatus",
          },
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
        },
      },
      chartConstanciasByVerificationStatus: {
        type: "bar",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          title: {
            display: true,
            text: "Distribución de constancias de uso de suelo por estatus de verificación",
          },
          scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    stepSize: 1
                }
            }],
            xAxes: [{
              gridLines: {
                  offsetGridLines: true
              }
          }]
        },
          plugins: {
            responsive: true,
            legend: {
              position: "top",
            },
          },
        },
      },
      chartConstanciasByDocumentStatus: {
        type: "horizontalBar",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          title: {
            display: true,
            text: "Distribución de estatus de documentacion de Constancias",
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  offsetGridLines: true,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  stepSize: 1,
                },
              },
            ],
          },
          plugins: {
            responsive: true,
            legend: {
              position: "top",
            },
          },
        },
      },
      chartConstanciasBlockedAndNotBlocked: {
        type: "horizontalBar",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          title: {
            display: true,
            text: "Distribución de Constancias bloqueadas y no bloqueadas",
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  offsetGridLines: true,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  stepSize: 1,
                },
              },
            ],
          },
          plugins: {
            responsive: true,
            legend: {
              position: "top",
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      id:'id', usuario: 'usuario', email: 'email', role:'role'
    })
  },
  mounted() {
    this.initialize();
    this.estadisticas();
  },
  methods: {
    initialize() {
      this.setGreeting();
      let processTypeId = 1;
      const userType = this.$store.state.role;
    },
    setGreeting() {
      const currentTime = new Date().getHours();
      if (currentTime >= 5 && currentTime < 12) {
        this.greeting = '¡Buenos días';
      } else if (currentTime >= 12 && currentTime < 18) {
        this.greeting = '¡Buenas tardes';
      } else {
        this.greeting = '¡Buenas noches';
      }
      this.greeting += ' ' + this.$store.state.usuario + '!';
    },
    async estadisticas(){
      await SolicitudService.obtenerEstadisticas({id:this.id}).then(
          (response) => {
            if(response.data.success){
              //console.log(response.data.data);
              this.solicitudesTotal      = response.data.data.solicitudesTotal;
              this.solicitudesPendientes = response.data.data.solicitudesPendientes;
              this.solicitudesCompletado = response.data.data.solicitudesCompletado;
              this.solicitudesRechazadas = response.data.data.solicitudesRechazadas;
              //this.$toast.success(response.data.message);
            }
            else{
              this.$toast.error(response.data.message);
            }
          }
      );
    },

  },
});
