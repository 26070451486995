
import Vue from 'vue';
import SolicitudService from '@/services/SolicitudService';
import { VForm } from "@/types/formvalidate";
import {CatalogoService} from "@/services/CatalogoService";
import {mapGetters} from "vuex";
import RULES from "@/mixins/rules";
import FormSeal from "@/components/Sat/FormSeal.vue";
import FormSealDirectora from "@/components/Sat/FormSealDirectora.vue";
import ViewPDFService from '@/services/ViewPDFService';
import {Vex} from "@/plugins/vex";
import moment from 'moment';


export default Vue.extend({
    name: 'Didsa',
    mixins: [RULES],
    components: {
      FormSeal,
      FormSealDirectora
    },
    data: () => ({
      //Dialogs
      dialogViewDeclaracion: false,
      dialogSendValidate: false,
      dialogPrintEdoCta: false,
      dialogFilter: false,
      dialogSeal: false,
      dialogR: false,
      documento: null,
      showBoton: false,
      solicitud: [],
      edoCtaActual: false,
      edoCtaAnterior: false,
      solicitudEdosCta: [],
      solicitudIdEdoCta: null,
      datoSendValidate: {
        id: null,
        folioSendValidate: null,
      },
      //Catalogos
      serviceEstatusSolicitudes: new CatalogoService('catalogos/estatus-solicitud'),
      serviceTipoOperacion: new CatalogoService('catalogos/tipo-operacion'),
      catalogos: {
        estatusSolicitud: [],
        tipoOperacion: []
      },
      //Datatable
      expanded: [],
      headers: [
        { text: '', align: 'center', sortable: false, value: 'exclusive' },
        { text: 'ESTATUS', align: 'start', sortable: false, value: 'estatus' /* value: role === 'notario' ? 'estatus.notario' : 'estatus.municipio' */ },
        { text: 'IDENTIFICADOR', align: 'start', sortable: false, value: 'uuid'  },
        { text: 'ESTABLECIMIENTO', align: 'start', sortable: false, value: 'establecimiento.nombre' },
        { text: 'PERIODO DECLARADO', align: 'start', sortable: false, value: 'periodo' },
        { text: 'FECHA DE DECLARACIÓN', align: 'start', sortable: false, value: 'fecha_registro' },
        { text: 'ORDEN DE PAGO', align: 'start', sortable: false, value: 'solicitud_historial' },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],
      list: [],
      //Formularios
      selected: [],
      formSearch: null,
      formFilter: {
        estatusSolicitud: null,
        tipoOperacion: null,
        claveCatrastal: null
      },
      formRechazo: {
        id: '',
        motivo_rechazo: '',
      },
      ruta: 'didsa',
      pagos:[],
      dialogPago:false,
      datosPago: {
        folio:'',
        fecha_emision:'',
        fecha_expiracion:'',
        estatus_id:''
      },
    }),
    computed: {
      formRe(): VForm {
        return this.$refs.formRechazo as VForm;
      },
      ...mapGetters({
            id:'id', usuario: 'usuario', modules: 'modules', email: 'email', status: 'status', role:'role'
        })
    },
    watch: {
        edoCtaActual:{
          handler: function (valor) {
            if(valor){
              this.edoCtaAnterior = false
            }
          }
        },
        edoCtaAnterior: {
          handler: function (valor) {
            if(valor) {
              this.edoCtaActual = false
            }
          }
        }
    },
    mounted() {
        this.getAllData();
        this.initialize();
    },
    methods: {
      //@ts-ignore
      formatPrecio(precio) {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(precio)
      },
      // @ts-ignore
      getColorByStatusContribuyente(status: string): string {
        if( this.role === 'hotelero' || this.role === 'embarcacion') {
          switch (status) {
            case 'PENDIENTE DE FIRMA':
              return '#F9A825';
            case 'FIRMADO, LISTO PARA ENVÍO':
              return '#42ade5';
            case 'EN VALIDACIÓN POR EL ÁREA':
              return '#439343';
            case 'RECHAZADO POR EL ÁREA':
              return '#E53935';
            case 'VALIDADO Y PENDIENTE DE FIRMA POR EL ÁREA':
              return '#4DB6AC';
            case 'FIRMADO POR EL ÁREA':
              return '#396926';
            case 'ORDEN DE PAGO GENERADO':
              return ' #7133ff';
            case 'FINALIZADO':
              return ' #555555';
            default:
              return 'grey';
          }
        }
        if( this.role === 'operativoMunicipio' ) {
          switch (status) {
            case 'PENDIENTE DE VALIDAR':
              return '#F9A825';
            case 'RECHAZADO POR EL ÁREA':
              return '#E53935';
            case 'PEND. FIRMA DIRECTORA':
              return '#2e93c4';
            case 'FIRMADO POR LA DIRECTORA':
              return '#43A047';
            case 'PAGADO':
              return '#33691E';
            case 'ORDEN DE PAGO GENERADO':
              return ' #7133ff';
            case 'FINALIZADO':
              return ' #555555';
            default:
              return 'grey';
          }
        }
      },
      //@ts-ignore
      async verPagos() {
        let datos = {ruta: this.ruta};
        await SolicitudService.checkPago(datos).then(
            response => {
              if (response.data.success) {
                this.pagos = response.data.data;
                this.pagos = this.pagos.filter(dt => dt != false)
                console.log(this.pagos);
                this.pagos == null ? this.dialogPago = false : this.dialogPago = true;
                this.pagos.length == 0 ? this.dialogPago = false : this.dialogPago = true;
                this.initialize();
                this.$toast.success(response.data.message);
              } else {
                this.$toast.error("Pagos no recuperados");
              }
            }
        );
      },
      selecteds(event: any, valor: never) {
        const datos: never[] = this.selected
        if (event){
          this.selected.push(valor)
          console.log(this.selected)
        }else{
          // @ts-ignore
          this.selected = datos.filter(dt => valor.id !== dt.id)
          console.log(this.selected)
        }
      },
      //@ts-ignore
      transformarPeriodo(periodo){
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const index = periodo.indexOf(",");
        var result1 = periodo.slice(0,index);
        var result2 = periodo.substring(index + 1);
        /*const date = new Date(result1);
        const month = date.getMonth();
        date.setMonth(month + 1);
        //@ts-ignore
        var formattedDate = new Intl.DateTimeFormat('es-ES', options).format(date);
        console.log(formattedDate);*/
        return result1 + "," + result2;
      },
      async getAllData() {
        try {
          const [estatusSolicitudRes] = await Promise.all([
            this.serviceEstatusSolicitudes.getAll()
          ]);
          if(this.role == 'operativoMunicipio'){
            // @ts-ignore
            this.catalogos.estatusSolicitud = estatusSolicitudRes.data.filter(dt => dt.id > 2);
          }else{
            this.catalogos.estatusSolicitud = estatusSolicitudRes.data;
          }
        } catch (error) {
          console.error("Error al obtener datos:", error);
          // Maneja el error apropiadamente según tu lógica de la aplicación
        }
      },
      async updateEstatus() {
        let dato = {estatus_id: 3}
        const data = await SolicitudService.updateStatus(this.datoSendValidate.id, dato);
        if (data.data.success) { //data['success']
          this.$toast.success(data.data.message);
        }else{
          this.$toast.error(data.data.message);
        }
        this.initialize();
        setTimeout(() => {
          this.dialogSendValidate = false
        },500)

      },
      async updateAprobar() {
        // solicitudIdEdoCta
        console.log(this.solicitud);
        // @ts-ignore
        Vex.dialog.confirm({
          // @ts-ignore
          message: `¿Realmente deseas aprobar el estado de cuenta generado?`,
          callback: async (value: any)  => {
            if (value) {
              let dato = {solicitud: this.solicitud}
              let {data} = await SolicitudService.updateAprobar(dato);
              if(data.success){
                this.$toast.success(`El estado de cuenta ha sido aprobado exitosamente`);
                await this.initialize();
                this.dialogPrintEdoCta = false;
                this.dialogViewDeclaracion = false;
              }else {
                this.$toast.error(data.message)
              }
            }
          }
        })
      },
      // @ts-ignore
      async descargarPdf(item) {
        await ViewPDFService.viewPDF( '/reportes/genera-declaracion', {solicitud:item}, 'POST', 'TEMP')
            .then((response) => {
              let file = new Blob([response.data],{type: response.headers['content-type']});
              const url = window.URL.createObjectURL(file);
              const tipoArchivo = 'test'
              //@ts-ignore
              this.documento = {
                title: tipoArchivo,
                url: url,
                mime: response.headers['content-type']
              }
              this.dialogViewDeclaracion = true;
              this.showBoton = false;
            });
      },
      async descargarPdfEstadoCuenta(item:any) {
        // const reporte_actual = this.edoCtaActual ? this.edoCtaActual : this.edoCtaAnterior ? this.edoCtaAnterior : null;
        await ViewPDFService.viewPDF( '/reportes/estado-cuenta', {solicitud:item, reporte_actual:this.edoCtaActual, reporte_anterior: this.edoCtaAnterior}, 'POST', 'TEMP')
            .then((response) => {
              let file = new Blob([response.data],{type: response.headers['content-type']});
              const url = window.URL.createObjectURL(file);
              const tipoArchivo = 'test'
              //@ts-ignore
              this.documento = {
                title: tipoArchivo,
                url: url,
                mime: response.headers['content-type']
              }
              this.dialogViewDeclaracion = true;
              this.showBoton = true;
            });
        this.initialize();
        this.dialogPrintEdoCta = false;
        // this.edoCtaActual = false;
        // this.edoCtaAnterior = false;

      },
      verDetalle(item: any){
        this.$router.push({name: 'formdidsareadonly', params: { didsaId: item }}).catch(() => {
        });

      },

      async initialize() {
        // console.log({statusSolicitud: this.formFilter, search: this.formSearch})
        let dato = {
          id:this.id,
          role:this.role,
          ruta:this.ruta,
          statusSolicitud: this.formFilter.estatusSolicitud,
          search: this.formSearch
        }
        await SolicitudService.getAll(dato).then(
            (data): any => {
              this.list = data.data;
              if(this.role === 'operativoMunicipio'){
                const datos = data.data
                this.list = datos.filter((dt: { estatus_id: number; }) => dt.estatus_id >= 3)
              }
            }
        );
        console.log(this.list);
      },
      // @ts-ignore
      async Validar(item){
        await SolicitudService.ValidarSoli({solicitud:item}).then(
            (response) => {
              if(response.data.success){
                this.$toast.success(response.data.message);
              }else{
                this.$toast.error(response.data.message);
              }
              this.initialize();
            }
        );
      },
      // @ts-ignore
      async RechazarDialog(item){
        this.formRechazo.id = item;
        this.dialogR = true;
      },
      // @ts-ignore
      async RechazarEnvio(){
        if(this.formRe.validate()){
          await SolicitudService.RechazarSoli(this.formRechazo).then(
              (response) => {
                if(response.data.success){
                  this.dialogR = false;
                  this.$toast.success(response.data.message);
                }else{
                  this.$toast.error(response.data.message);
                }
                this.initialize();
              }

          );
        }
      },
    }
})
