import API from '@/plugins/axios'
import API2 from "@/plugins/axiosApi";
import store from '@/store/index'

class LoginService {
    login(data: {email: string, password: string}): Promise<any> {
        return API.post(`/login`, data);
    }
    signUp(data: any): Promise<any> {
        return API.post(`/signUp`, data);
    }
    RecoveryPassword(data: any): Promise<any> {
        return API.post(`/recoveryPassword`, data);
    }
    changePassword(id: any, data: any): Promise<any> {
        return API.post(`/changepassword/${id}`, data);
    }
    updateNip(id: any, data: {old_password: string, password: string, c_password: string}): Promise<any> {
        return API.post(`/profile/${id}/password`, data);
    }
    logout(): Promise<any>{
        return API.post(`/logout/${store.state.id}`);
    }
    notificaciones(id: any): Promise<any> {
        return API.get(`/notificaciones/${id}`);
    }
    readNotification(id: any, notificacion_id: any): Promise<any> {
        return API.post(`/notificacion/${id}/${notificacion_id}`);
    }
    sendNotification(roleId: number, data: any): Promise<any> {
        return API.post(`/notario/sendNotification/${roleId}`, data);
    }
    readAllNotification(id: any): Promise<any> {
        return API.post(`/read-all-notificacion/${id}`);
    }
    getApiData(rfc: string) : Promise<any> {
        return API.post(`searchRFC`,rfc);
    }
}

export default new LoginService();