
import Vue from 'vue';
import SolicitudService from '@/services/SolicitudService';
import SimService from "@/services/SimService";
import { VForm } from "@/types/formvalidate";
import {CatalogoService} from "@/services/CatalogoService";
import {mapGetters} from "vuex";
import RULES from "@/mixins/rules";
import FormSeal from "@/components/Sat/FormSeal.vue";
import FormSealDirectora from "@/components/Sat/FormSealDirectora.vue";
import ViewPDFService from '@/services/ViewPDFService';
import {Vex} from "@/plugins/vex";
import {forEach} from "lodash";



export default Vue.extend({
    name: 'Didsa',
    mixins: [RULES],
    components: {
      FormSealDirectora,
      FormSeal
    },
    data: () => ({
      //Dialogs
      dialogViewDeclaracion: false,
      dialogSendValidate: false,
      dialogPrintEdoCta: false,
      dialogFilter: false,
      dialogSeal: false,
      dialogEdo: false,
      dialogR: false,
      menu1:false,
      menu2:false,
      documento: null,
      showBoton: false,
      solicitud: [],
      edoCtaActual: false,
      edoCtaAnterior: false,
      solicitudEdosCta: [],
      solicitudIdEdoCta: null,
      solicitudIdsEdoCtas: [],
      solicitudEdoCtasHistorial: [],
      actualExist: false,
      datoSendValidate: {
        id: null,
        folioSendValidate: null,
      },
      //Catalogos
      serviceEstatusSolicitudes: new CatalogoService('catalogos/estatus-solicitud'),
      serviceTipoOperacion: new CatalogoService('catalogos/tipo-operacion'),
      catalogos: {
        estatusSolicitud: [],
        tipoOperacion: []
      },
      //Datatable
      expanded: [],
      headers: [
        { text: '', align: 'center', sortable: false, value: 'exclusive' },
        { text: 'ESTATUS', align: 'start', sortable: false, value: 'estatus' /* value: role === 'notario' ? 'estatus.notario' : 'estatus.municipio' */ },
        { text: 'IDENTIFICADOR', align: 'start', sortable: false, value: 'uuid'  },
        { text: 'BUQUE/ESTABLECIMIENTO', align: 'start', sortable: false, value: 'buqueOrEstablecimineto' },
        { text: 'FECHA DE ARRIBO/PERIODO', align: 'start', sortable: false, value: 'fecha_arriboOrPeriodo' },
        { text: 'FECHA DE DECLARACIÓN', align: 'start', sortable: false, value: 'fecha_registro' },
        { text: 'ORDEN DE PAGO', align: 'start', sortable: false, value: 'solicitud_historial' },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],
      list: [],
      anios: [],
      //Formularios
      formFilter: {
        anio_busqueda: 2024,
        fecha_inicio: null,
        fecha_fin: null,
      },
      selected: [],
      selectedEdo:[],
      formSearch: null,
      formRechazo: {
        id: '',
        motivo_rechazo: '',
      },
      lineaNav:0,
      ruta: 'didsac',
      dialogPago:false,
      datosPago: {
        folio:'',
        fecha_emision:'',
        fecha_expiracion:'',
        estatus_id:''
      },
    }),
    computed: {
      formRe(): VForm {
        return this.$refs.formRechazo as VForm;
      },
        ...mapGetters({
            id:'id', usuario: 'usuario', modules: 'modules', email: 'email', status: 'status', role:'role'
        })
    },
    watch: {
      edoCtaActual:{
        handler: function (valor) {
          if(valor){
            this.edoCtaAnterior = false
          }
        }
      },
      edoCtaAnterior: {
        handler: function (valor) {
          if(valor) {
            this.edoCtaActual = false
          }
        }
      }
    },
    mounted() {
      this.generarAnios();
      this.initialize();
    },
    methods: {
      generarAnios(){
        const actual = new Date().getFullYear();
        const inicioAnios = 2024;
        for(let anio = actual; anio >= inicioAnios; anio--){
          //@ts-ignore
          this.anios.push(anio);
        }
      },
      //@ts-ignore
      formatPrecio(precio) {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(precio)
      },
      // @ts-ignore
      getColorByStatusContribuyente(status: string): string {
        if( this.role === 'hotelero' || this.role === 'embarcacion') {
          switch (status) {
            case 'PENDIENTE DE FIRMA':
              return '#F9A825';
            case 'FIRMADO, LISTO PARA ENVÍO':
              return '#42ade5';
            case 'EN VALIDACIÓN POR EL ÁREA':
              return '#439343';
            case 'RECHAZADO POR EL ÁREA':
              return '#E53935';
            case 'VALIDADO Y PENDIENTE DE FIRMA POR EL ÁREA':
              return '#4DB6AC';
            case 'FIRMADO POR EL ÁREA':
              return '#396926';
            case 'ORDEN DE PAGO GENERADO':
              return ' #7133ff';
            case 'FINALIZADO':
              return ' #555555';
            default:
              return 'grey';
          }
        }
        if( this.role === 'operativoMunicipio' ) {
          switch (status) {
            case 'PENDIENTE DE VALIDAR':
              return '#F9A825';
            case 'RECHAZADO POR EL ÁREA':
              return '#E53935';
            case 'PEND. FIRMA DIRECTORA':
              return '#2e93c4';
            case 'FIRMADO POR LA DIRECTORA':
              return '#43A047';
            case 'PAGADO':
              return '#33691E';
            case 'ORDEN DE PAGO GENERADO':
              return ' #7133ff';
            case 'FINALIZADO':
              return ' #555555';
            default:
              return 'grey';
          }
        }
      },
      selecteds(event: any, valor: never) {
        const datos: never[] = this.selected
        if (event){
          this.selected.push(valor)
          console.log(this.selected)
        }else{
          // @ts-ignore
          this.selected = datos.filter(dt => valor.id !== dt.id)
          console.log(this.selected)
        }
      },

      selectedEdos(event: any, valor: never) {
        const datos: never[] = this.selectedEdo;
        const datos2: never[] = this.solicitudEdoCtasHistorial;
        if (event){
            this.selectedEdo.push(valor)
            //@ts-ignore
            this.solicitudEdoCtasHistorial.push([valor.id,valor.solicitud_historial[0].solicitud_edo_cta]);
            //@ts-ignore
            this.solicitudEdoCtasHistorial.forEach(item => {
              //@ts-ignore
              if(item[1].length > 0){
                this.actualExist = true;
              }else{
                this.actualExist = false;
              }
            });
            //@ts-ignore
            this.lineaNav = this.selectedEdo[0].buque.lineaNaviera_id;
        }else{
          // @ts-ignore
          this.selectedEdo = datos.filter(dt => valor.id !== dt.id);
          //@ts-ignore
          this.solicitudEdoCtasHistorial = datos2.filter( dt => valor.id !== dt[0] );

          this.solicitudEdoCtasHistorial.forEach(item => {
            //@ts-ignore
            if(item[1].length > 0){
              this.actualExist = true;
            }else{
              this.actualExist = false;
            }
          });
          if(this.solicitudEdoCtasHistorial.length == 0){
            this.actualExist = false;
          }
          if(this.selectedEdo.length == 0){
            this.lineaNav = 0;
          }
        }
        console.log(this.actualExist)
      },
      async initialize() {
        // console.log({statusSolicitud: this.formFilter, search: this.formSearch})
        let dato = {
          anio:this.formFilter.anio_busqueda,
          fecha_inicio:this.formFilter.fecha_inicio,
          fecha_fin:this.formFilter.fecha_fin
        }

        await SimService.getAllPagos(dato).then(
            (data): any => {
              let datos = data.data;
              if(datos.success){
                this.list = datos.data;
                this.$toast.success(datos.message);
              }else{
                this.$toast.error(datos.message);
              };
            }
        );
      },
      //@ts-ignore
      async verPago(folio){
        this.dialogPago = true;
        //console.log(folio);
        let datos = {'folio_control':folio};
        let {data} = await SolicitudService.checkPago(datos);
        if(data.success){
          this.datosPago = data.data;
          this.$toast.success(data.message);
        }else{
          this.$toast.error(data.message);
        };
        console.log(data);
      },
      async updateEstatus() {
        let dato = {estatus_id: 3}
        const data = await SolicitudService.updateStatus(this.datoSendValidate.id, dato);
        if (data.data.success) { //data['success']
          this.$toast.success(data.data.message);
        }else{
          this.$toast.error(data.data.message);
        }
        this.initialize();
        setTimeout(() => {
          this.dialogSendValidate = false
        },500)

      },
      async updateAprobar() {
        // solicitudIdEdoCta
        console.log(this.solicitud);
        // @ts-ignore
        Vex.dialog.confirm({
          // @ts-ignore
          message: `¿Realmente deseas aprobar el estado de cuenta generado?`,
          callback: async (value: any)  => {
            if (value) {
              let dato = {solicitud: this.solicitud}
              let {data} = await SolicitudService.updateAprobar(dato);
              if(data.success){
                this.$toast.success(`El estado de cuenta ha sido aprobado exitosamente`);
                await this.initialize();
                this.dialogPrintEdoCta = false;
                this.dialogViewDeclaracion = false;
              }else {
                this.$toast.error(data.message)
              }
            }
          }
        })
      },
      //@ts-ignore
      async descargarPdf(item) {
        await ViewPDFService.viewPDF( '/reportes/genera-declaracion', {solicitud:item}, 'POST', 'TEMP')
            .then((response) => {
              let file = new Blob([response.data],{type: response.headers['content-type']});
              const url = window.URL.createObjectURL(file);
              const tipoArchivo = 'test'
              //@ts-ignore
              this.documento = {
                title: tipoArchivo,
                url: url,
                mime: response.headers['content-type']
              }
              this.dialogViewDeclaracion = true;
              this.showBoton = false;
            });
      },
      async descargarPdfEstadoCuenta(item:any) {
        // const reporte_actual = this.edoCtaActual ? this.edoCtaActual : this.edoCtaAnterior ? this.edoCtaAnterior : null;
        await ViewPDFService.viewPDF( '/reportes/estado-cuenta', {solicitud:item, ruta:'didsac', reporte_actual:this.edoCtaActual, reporte_anterior: this.edoCtaAnterior}, 'POST', 'TEMP')
            .then((response) => {
              let file = new Blob([response.data],{type: response.headers['content-type']});
              const url = window.URL.createObjectURL(file);
              const tipoArchivo = 'test'
              //@ts-ignore
              this.documento = {
                title: tipoArchivo,
                url: url,
                mime: response.headers['content-type']
              }
              this.dialogViewDeclaracion = true;
              this.showBoton = true;
            });
        this.initialize();
        this.dialogPrintEdoCta = false;
        // this.edoCtaActual = false;
        // this.edoCtaAnterior = false;

      },
      // @ts-ignore
      async Validar(item){
        await SolicitudService.ValidarSoli({solicitud:item}).then(
            (response) => {
              if(response.data.success){
                this.$toast.success(response.data.message);
              }else{
                this.$toast.error(response.data.message);
              }
              this.initialize();
            }
        );
      },
      // @ts-ignore
      async RechazarDialog(item){
        this.formRechazo.id = item;
        this.dialogR = true;
      },
      // @ts-ignore
      async RechazarEnvio(){
        if(this.formRe.validate()){
          await SolicitudService.RechazarSoli(this.formRechazo).then(
              (response) => {
                if(response.data.success){
                  this.dialogR = false;
                  this.$toast.success(response.data.message);
                }else{
                  this.$toast.error(response.data.message);
                }
                this.initialize();
              }

          );
        }
      },
    }
})
