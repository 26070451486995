
  import Vue from 'vue';
  import {mapGetters} from "vuex";
  import rules from "@/mixins/rules";
  import SolicitudService from '@/services/SolicitudService';
  import {VForm} from '@/types/formvalidate';
  // @ts-ignore
  import EventBus from "@/plugins/event-bus";
  import SimService from "@/services/SimService";
  import {CatalogoService} from "@/services/CatalogoService";
  import DiasService from "@/services/DiaService";
  import CustomFile from "@/components/Common/CustomFile.vue";
  import createNumberMask from 'text-mask-addons/dist/createNumberMask';
  import moment from 'moment';
  import numeric from '@/directives/numeric';
  import {parseInt} from "lodash";
  import {Solicitud} from "@/models/Solicitud";

  export default Vue.extend({
    name: 'FormDidsaC',
    mixins: [rules],
    components: {
        CustomFile,
    },
    data: () => ({
      serviceUma: new CatalogoService('catalogos/uma'),
      serviceNavieras: new CatalogoService('catalogos/lineas_navieras'),
      menu: false,
      valor_uma:0,
      formSolicitud: new Solicitud(),
      listBuques: [],
      listNavieras:[],
      documentos:false,
      datos: [],
      textDerecho: '0',
      textPagar : '0',
      currencyMask: createNumberMask({
        prefix: '',
        allowDecimal: false,
        thousandsSeparatorSymbol: '',
        allowNegative: false,
      }),
      Meses: [
        { id: '01', nombre: "ENERO" },
        { id: '02', nombre: "FEBRERO" },
        { id: '03', nombre: "MARZO" },
        { id: '04', nombre: "ABRIL" },
        { id: '05', nombre: "MAYO" },
        { id: '06', nombre: "JUNIO" },
        { id: '07', nombre: "JULIO" },
        { id: '08', nombre: "AGOSTO" },
        { id: '09', nombre: "SEPTIEMBRE" },
        { id: '10', nombre: "OCTUBRE" },
        { id: '11', nombre: "NOVIEMBRE" },
        { id: '12', nombre: "DICIEMBRE" }
      ],
    }),
    watch: {
        
    },
    computed: {
      form(): VForm {
        return this.$refs.form as VForm
      },
      ...mapGetters({
          usuario: 'usuario', id: 'id', mini: 'sidebar'
      }),
    },
    mounted() {
      //@ts-ignore
      this.formSolicitud.rfc = this.$store.getters.rfc;
      //@ts-ignore
      this.formSolicitud.representante = this.$store.getters.usuario;
      //@ts-ignore
      this.formSolicitud.email = this.$store.getters.email;
      //@ts-ignore
      this.formSolicitud.operacion = 2;
      console.log(this.formSolicitud);
      this.getNaviera();
    },
    methods: {
      getFecha(fecha: string){
        this.formSolicitud.mes = fecha.slice(5,7);
        this.formSolicitud.anio = fecha.slice(0,4);
      },
      async getNaviera(){
        let dato = {id: this.id}
        const [navieras] =  await Promise.all([
          this.serviceNavieras.getAll(dato),
        ]);
        //@ts-ignore
        navieras.data.forEach(item => {
          //@ts-ignore
          item.puerto.linea_nav.forEach(item => {
          //@ts-ignore
          this.listNavieras.push(item);
          //@ts-ignore
          this.$set(this.listBuques,item.id,item.buques);
          })
        });
      },
      setdatos(){
        //@ts-ignore
        this.datos = this.listNavieras.filter(dt => dt.id == this.formSolicitud.linea_id);
        //@ts-ignore
        this.formSolicitud.calle = this.datos[0].calle;
        //@ts-ignore
        this.formSolicitud.numExterior = this.datos[0].numero_exterior;
        //@ts-ignore
        this.formSolicitud.numInterior = this.datos[0].numero_interior;
        //@ts-ignore
        this.formSolicitud.codigoPostal = this.datos[0].codigo_postal;
        //@ts-ignore
        this.formSolicitud.colonia = this.datos[0].colonia;
        //@ts-ignore
        this.formSolicitud.localidad = this.datos[0].localidad;
        //@ts-ignore
        if(this.datos[0].municipio == null){
          this.formSolicitud.municipio = "OTHON P. BLANCO"
        }else{
          //@ts-ignore
          this.formSolicitud.municipio = this.datos[0].municipio;
        }
        //@ts-ignore
        if(this.datos[0].entidad == null){
          this.formSolicitud.estado = "QUINTANA ROO"
        }else {
          //@ts-ignore
          this.formSolicitud.estado = this.datos[0].entidad;
        }
        console.log(this.datos);
      },
      async calculo (){
        if (this.form.validate()) {
          //@ts-ignore
          if (this.formSolicitud.fecha_arribo == '') {
            this.$toast.warning("Debe seleccionar la fecha de arribo");
            return;
          }
          //@ts-ignore
          if (this.formSolicitud.calculo.pasajeros_gravados == 0) {
            this.$toast.warning("Debe proporcionar el NÚMERO de pasajeros gravados");
            return;
          }
          //@ts-ignore
          this.formSolicitud.calculo.total_pasajeros = parseInt(this.formSolicitud.calculo.pasajeros_gravados) + parseInt(this.formSolicitud.calculo.exentos);
          const fecha1 = new Date(this.formSolicitud.fecha_arribo)
          //@ts-ignore
          var fechaActual = fecha1.getFullYear();
          let dato = {search: fechaActual}
          const [umaRes] = await Promise.all([
            this.serviceUma.getAll(dato),
          ]);
          //Umas
          if (umaRes.data.length > 0) {
            this.valor_uma = umaRes.data[0].valor_diario;
          }
          //no hay uma vigente
          else {
            this.$toast.warning("No hay registro de uma vigente");
          }

          var uma = this.valor_uma * .15;
          //@ts-ignore
          this.formSolicitud.calculo.tarifa = uma.toFixed(2);
          this.formSolicitud.calculo.derecho = this.formSolicitud.calculo.tarifa * this.formSolicitud.calculo.total_pasajeros;
          this.formSolicitud.calculo.total_pagar =this.formSolicitud.calculo.derecho
          this.textDerecho = new Intl.NumberFormat().format(this.formSolicitud.calculo.derecho);
          this.textPagar = new Intl.NumberFormat().format(this.formSolicitud.calculo.derecho);
        }else{
          this.$toast.warning("Faltan datos por rellenar de manera correcta");
          return;
        }

      },
      async save(){
        if (this.form.validate()) {
          if (this.formSolicitud.calculo.total_pagar == 0) {
            this.$toast.warning("Debe proporcionar el NÚMERO de pasajeros gravados");
            return;
          }
          await SolicitudService.create(this.formSolicitud).then(
              (response) => {
                console.log(response.data);
                if (response.data.success) {
                  this.$toast.success(response.data.message);
                  this.$router.push({name: 'didsac'}).catch(() => {});
                } else {
                  this.$toast.warning(response.message);
                }
              }
          );
        }else{
          this.$toast.warning("Faltan datos por rellenar");
          return;
        }
      },
      close() {
        this.$router.push({name: 'didsac'}).catch(() => {
        });
      },
    },
  })
  