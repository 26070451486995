<template>
    <v-container>
        <v-row>
            <v-col>

            </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" v-if="(role == 'embarcacion') ? false : true">
            <v-card shaped raised color="">
              <v-list-item three-line :to="{ name: 'didsa'}">
                <v-list-item-avatar
                    tile
                    size="80"
                >
                  <v-icon size="80">mdi-office-building</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="title">DIDSA</div>
                  <v-list-item-title class="headline mb-1" >
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-right"></v-list-item-subtitle>
                </v-list-item-content>


              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="6" v-if="(role == 'hotelero') ? false : true">
            <v-card shaped raised color="">
              <v-list-item three-line :to="{ name: 'didsac'}">
                <v-list-item-avatar
                    tile
                    size="80"
                >
                  <v-icon size="80">mdi-ferry</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="title">DIDSAC</div>
                  <v-list-item-title class="headline mb-1" >
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-right"></v-list-item-subtitle>
                </v-list-item-content>


              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
    </v-container>
</template>


<script>
import Vue from 'vue'

import LineChart from '@/components/charts/LineChart.js'
import PieChart from "@/components/charts/PieChart";
import AreaChart from "@/components/charts/AreaChart";
import StackedChart from "@/components/charts/StackedChart";
import BarChart from "@/components/charts/BarChart";
import {mapGetters} from "vuex";

export default {
    name: 'Dashboard',
    mixins: [],
    components: {
      LineChart, PieChart, AreaChart, StackedChart, BarChart,
    },
    directives: {},
    data: () => ({
      
    }),
    mounted() {
      
    },
    computed: {
        ...mapGetters({
        usuario: 'usuario', modules: 'modules' , email: 'email' , perfil: 'perfil', role: 'role'
      })
    },
    watch: {},
    methods: {
    }
}
</script>
<style>
.small {
  position: relative;
  max-height: 100px;
}
</style>
