
  import Vue from 'vue';
  import {mapGetters} from "vuex";
  import {Solicitud} from '@/models/Solicitud';
  import rules from "@/mixins/rules";
  import CardAdquiriente from '@/components/Isabi/CardAdquiriente.vue';
  import CardEnajenante from '@/components/Isabi/CardEnajenante.vue';
  import {Enajenante} from "@/models/Enajenante";
  import { Adquiriente } from '@/models/Adquiriente';
  import SolicitudService from '@/services/SolicitudService';
  import {VForm} from '@/types/formvalidate';
  // @ts-ignore
  import EventBus from "@/plugins/event-bus";
  import SimService from "@/services/SimService";
  import {CatalogoService} from "@/services/CatalogoService";
  import DiasService from "@/services/DiaService";
  import CustomFile from "@/components/Common/CustomFile.vue";
  import createNumberMask from 'text-mask-addons/dist/createNumberMask';
  import moment from 'moment';
  import moment2 from 'moment-business-days';
  import UserService from "@/services/UserService";
  import numeric from '@/directives/numeric';
  
  export default Vue.extend({
    name: 'FormDidsa',
    mixins: [rules],
    components: {
        CustomFile,
    },
    data: () => ({
      serviceUma: new CatalogoService('catalogos/uma'),
      serviceEstablecimiento: new CatalogoService('catalogos/establecimiento'),
      valor_uma: 0,
      menu1: false,
      sameName: false,
      currencyMask: createNumberMask({
        prefix: '',
        allowDecimal: false,
        thousandsSeparatorSymbol: '',
        allowNegative: false,
      }),
      userEstablecimiento:[],
      selectedId:0,
      documentos:false,
      formSolicitud:new Solicitud(),
      textPaga: '0',
      datos:[],
    }),
    watch: {
        
    },
    computed: {
      form(): VForm {
        return this.$refs.form as VForm
      },
      ...mapGetters({
            usuario: 'usuario', id: 'id', mini: 'sidebar'
        }),
    },
    mounted() {
      this.formSolicitud.rfc = this.$store.getters.rfc;
      this.formSolicitud.user_id = this.$store.getters.id;
      if(this.$store.getters.usuario.length == 0){
        this.$toast.warning("Por favor, modifique el nombre del contribuyente en el apartado correspondiente para poder registrar una solicitud.");
      }else{
        this.formSolicitud.nombre_persona = this.$store.getters.usuario;
      }
      this.formSolicitud.operacion = 1;
      this.getEstablecimiento();
    },
    methods: {
      handleFileUploadSuccess(data: any) {
        // type FormUserKey = keyof typeof this.formUser;
        const typeFile = data.typeFile;
        const message = data.message;
        //@ts-ignore
        this.formUser[typeFile] = null;
        this.$toast.success(message);
      },
      handleFileUploadError (data: any){
        const message = data.message;
        this.$toast.error(message);
      },
      cleanData(event: any){
        if(event){
          this.formSolicitud.calculo.numHabitaciones = 0;
          this.formSolicitud.calculo.numHabitacionesOcupadas = 0;
          this.formSolicitud.calculo.porcentaje_Ocupacion = 0;
          this.formSolicitud.calculo.uma = 0;
          this.formSolicitud.calculo.derecho_pagar = 0;
          this.textPaga = '0';
        }
      },
      useSameUser(event: any) {
        if (event) {
          this.formSolicitud.representante = this.formSolicitud.nombre_persona;
          this.sameName = true;
        }else{
          this.formSolicitud.representante = '';
          this.sameName = false;
        }
      },
      async getEstablecimiento(){
        const [establecimientos] =  await Promise.all([
            this.serviceEstablecimiento.getAll(),
        ]);
        if(establecimientos.data.data.user_establecimiento.length == 0){
          this.formSolicitud.nombre_establecimiento = establecimientos.data.data.establecimiento.nombre;
          this.formSolicitud.calle = establecimientos.data.data.establecimiento.calle;
          this.formSolicitud.numExterior =  establecimientos.data.data.establecimiento.numero_exterior;
          this.formSolicitud.numInterior =  establecimientos.data.data.establecimiento.numero_interior;
          this.formSolicitud.codigoPostal =  establecimientos.data.data.establecimiento.codigo_postal;
          this.formSolicitud.licencia = establecimientos.data.data.establecimiento.licencia;
          this.formSolicitud.colonia =  establecimientos.data.data.establecimiento.colonia;
          this.formSolicitud.localidad =  establecimientos.data.data.establecimiento.localidad;
          this.formSolicitud.municipio =  establecimientos.data.data.establecimiento.municipio;
          this.formSolicitud.estado =  establecimientos.data.data.establecimiento.entidad;
          this.formSolicitud.establecimiento_id = establecimientos.data.data.establecimiento.id;
          this.formSolicitud.id_establecimiento_api = establecimientos.data.data.establecimiento.id_establecimiento_api;
          this.formSolicitud.calculo.numHabitaciones = establecimientos.data.data.establecimiento.numHabitaciones == null ? 0 : establecimientos.data.data.establecimiento.numHabitaciones;
        }else{
          //@ts-ignore
          establecimientos.data.data.user_establecimiento.forEach(item => {
            //@ts-ignore
            this.userEstablecimiento.push(item.establecimiento);
          })
        }
      },
      setdatos(){
        //@ts-ignore
        this.datos = this.userEstablecimiento.filter(dt => dt.id == this.selectedId);//@ts-ignore
        console.log(this.datos);//@ts-ignore
        this.formSolicitud.nombre_establecimiento = this.datos[0].nombre;//@ts-ignore
        this.formSolicitud.calle = this.datos[0].calle;//@ts-ignore
        this.formSolicitud.numExterior =  this.datos[0].numero_exterior;//@ts-ignore
        this.formSolicitud.numInterior =  this.datos[0].numero_interior;//@ts-ignore
        this.formSolicitud.codigoPostal =  this.datos[0].codigo_postal;//@ts-ignore
        this.formSolicitud.licencia = this.datos[0].licencia;//@ts-ignore
        this.formSolicitud.colonia =  this.datos[0].colonia;//@ts-ignore
        this.formSolicitud.localidad =  this.datos[0].localidad;//@ts-ignore
        this.formSolicitud.municipio =  this.datos[0].municipio;//@ts-ignore
        this.formSolicitud.estado =  this.datos[0].entidad;//@ts-ignore
        this.formSolicitud.establecimiento_id = this.datos[0].id;//@ts-ignore
        this.formSolicitud.id_establecimiento_api = this.datos[0].id_establecimiento_api;
      },
      //Calculo
      async calculoPorcentaje () {
        if (this.form.validate()) {
          if (this.formSolicitud.periodo == null) {
            this.$toast.warning("Debe seleccionar la fecha de inicio y fin de periodo");
            return;
          }
          if (this.formSolicitud.calculo.numHabitaciones == 0) {
            this.$toast.warning("Debe proporcionar el NÚMERO de habitaciones del establecimiento");
            return;
          }
          if (this.formSolicitud.calculo.numHabitacionesOcupadas == 0) {
            this.$toast.warning("Debe proporcionar el NÚMERO de habitaciones del establecimiento ocupadas durante el periodo");
            return;
          }
          //@ts-ignore
          const inicioPeriodo = moment(this.formSolicitud.periodo[0]);
          //@ts-ignore
          const finalPeriodo = moment(this.formSolicitud.periodo[1]);
          //@ts-ignore
          const fecha1 = new Date(this.formSolicitud.periodo[0]);
          //@ts-ignore
          var fechaActual = fecha1.getFullYear();
          let dato = {search: fechaActual}
          const [umaRes] = await Promise.all([
            this.serviceUma.getAll(dato),
          ]);
          //Umas
          if (umaRes.data.length > 0) {
            this.valor_uma = umaRes.data[0].valor_diario;
          }
          //no hay uma vigente
          else {
            this.$toast.warning("No hay registro de uma vigente");
          }
          //@ts-ignore
          const porcentage = this.formSolicitud.calculo.numHabitacionesOcupadas / (this.formSolicitud.calculo.numHabitaciones * finalPeriodo.diff(inicioPeriodo, 'days'));
          //@ts-ignore
          this.formSolicitud.calculo.porcentaje_Ocupacion = (porcentage * 100).toFixed(2);
          var umaVal = this.valor_uma * .15;
          //@ts-ignore
          this.formSolicitud.calculo.uma = umaVal.toFixed(2);
          //@ts-ignore
          this.formSolicitud.calculo.derecho_pagar = (this.formSolicitud.calculo.uma * this.formSolicitud.calculo.numHabitacionesOcupadas).toFixed(2);
          this.textPaga = new Intl.NumberFormat().format(this.formSolicitud.calculo.derecho_pagar);
        }else{
          this.$toast.warning("Faltan datos por rellenar de manera correcta");
          return;
        }
      },
      async save(){
        if (this.form.validate()) {
          if(this.documentos == false){
            if (this.formSolicitud.calculo.porcentaje_Ocupacion == 0) {
              this.$toast.warning("Debe realizadar el calculo de los datos");
              return;
            }
            if (this.formSolicitud.calculo.derecho_pagar == 0) {
              this.$toast.warning("Debe realizadar el calculo de los datos");
              return;
            }
          }
          await SolicitudService.create(this.formSolicitud).then(
              (response) => {
                console.log(response.data);
                if (response.data.success) {
                  this.$toast.success(response.data.message);
                  this.$router.push({name: 'didsa'}).catch(() => {});
                } else {
                  this.$toast.warning(response.message);
                }
              }
          );
        }else{
          this.$toast.warning("Faltan datos por rellenar");
          return;
        }
      },
      close() {
        this.$router.push({name: 'didsa'}).catch(() => {
        });
      },
    },
  })
  