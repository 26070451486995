
import Vue from "vue";
import { mapGetters } from "vuex";
import RULES from "@/mixins/rules";
import { VForm } from "@/types/formvalidate";
import CustomFile from "@/components/Common/CustomFile.vue";
import {CatalogoService} from "@/services/CatalogoService";
import {Usuario} from "@/models/Usuario";
import UserService from "@/services/UserService";
// @ts-ignore
export default Vue.extend({
    name: "FormReadOnly",
    components: {
        CustomFile,
    },
    mixins: [RULES],
    data() {
    return {
        serviceNotaria: new CatalogoService('notarias'),
        terminated: true,
        notarias: [],
        formDefault: new Usuario(),
        formUser : new Usuario(),
        userId: '',
        editar: false,
        dialog: false,
        formRechazo: {
            id: '',
            motivo_rechazo: '',
        },
        noShow: true
    };
    },
    computed: {

        form(): VForm {
            return this.$refs.form as VForm;
        },
        formRe(): VForm {
            return this.$refs.formRechazo as VForm;
        },
        ...mapGetters({
            usuario: 'usuario', modules: 'modules' , email: 'email' , perfil: 'perfil', id:'id', status: 'status', mini: 'sidebar', role:'role', 
        })
    },
    // @ts-ignore
    watch: {
        "formUser.isSameUser": async function(newVal) {
            if(newVal === true){
                await UserService.getRfc(this.id).then( res => {
                    this.formUser.rfc =  res.data.data.rfc;
                    this.formUser.email = this.email;
                });
            } else {
                this.formUser.rfc =  '';
                this.formUser.email = '';
            }
        },
    },
    mounted() {
        this.userId = this.$route.params.userId;
        this.editar = Boolean(this.$route.params.editar);
        console.log(this.editar);
        this.getData();
    },
    methods: {
        handleFileUploadSuccess(data: any) {
            // type FormUserKey = keyof typeof this.formUser;
            const typeFile = data.typeFile;
            const message = data.message;
            //@ts-ignore
            this.formUser[typeFile] = null;
            this.$toast.success(message);
        },
        handleFileUploadError (data: any){
            const message = data.message;
            this.$toast.error(message);
        },
        async getData(){
            console.log(this.userId);
            await UserService.getUser(parseInt(this.userId)).then(
                (response) => {
                    if(response.data.success){
                        //console.log(response.data.data.data);
                        this.formDefault = response.data.data.data;
                        let user = response.data.data.data;
                        // @ts-ignore
                        this.formUser.id = this.userId;
                        // @ts-ignore
                        this.formUser.nombre = this.formDefault.nombre;
                        // @ts-ignore
                        this.formUser.primer_apellido = this.formDefault.primer_apellido;
                        // @ts-ignore
                        this.formUser.segundo_apellido = this.formDefault.segundo_apellido;
                        // @ts-ignore
                        this.formUser.rfc = this.formDefault.rfc;
                        // @ts-ignore
                        this.formUser.email = this.formDefault.email;
                        // @ts-ignore
                        this.formUser.motivo_rechazo = this.formDefault.motivo_rechazo;
                        // @ts-ignore
                        this.formUser.status = this.formDefault.estatus_id;
                        //@ts-ignore
                        this.formUser.user_puerto = user.user_puerto;
                        //console.log(user.user_puerto.length);
                        if (user.user_puerto.length != 0){
                          let puerto = '';
                          let linea = '';
                          //@ts-ignore
                          user.user_puerto.forEach(item=>{
                            //@ts-ignore
                            puerto += item.puerto.puerto + ' , ';
                            //@ts-ignore
                            item.puerto.linea_nav.forEach(item2 => {
                              //@ts-ignore
                              linea += item2.nombre + ',';
                            });
                          });
                          //@ts-ignore
                          this.formUser.linea_naviera = puerto;
                          //@ts-ignore
                          this.formUser.direccion_naviera = linea;
                        }else{
                          //@ts-ignore
                          this.formUser.nombre_establecimineto = this.formDefault.establecimiento.nombre;
                          //@ts-ignore
                          this.formUser.direccion_establecimineto = this.formDefault.establecimiento.calle;
                          //@ts-ignore
                          this.formUser.localidad_establecimineto = this.formDefault.establecimiento.localidad;
                          //@ts-ignore
                          this.formUser.municipio_establecimineto = this.formDefault.establecimiento.municipio;
                        }
                        console.log(this.formDefault);
                    }
                }
            );
        },
        async getNotarias(){
            let {data} = await this.serviceNotaria.getAll();
            this.notarias = data;
        },
        //@ts-ignore
        async update(){
            await UserService.updateNotario(this.formUser).then(
                (response) => {
                  if(response.data.success){
                    this.$toast.success(response.data.message);
                    location.reload();
                  }else{
                    this.$toast.error(response.data.message);
                  }
                }
            );
        },
        // @ts-ignore
        async Validar(){
            await UserService.setValidar(parseInt(this.userId)).then(
                (response) => {
                    if(response.data.success){
                        this.$toast.success(response.data.message);
                        location.reload();
                    }else{
                        this.$toast.error(response.data.message);
                    }
                }
            );
        },
        // @ts-ignore
        async RechazarDialog(){
            this.formRechazo.id = this.userId;
            this.dialog = true;
        },
        // @ts-ignore
        async RechazarEnvio(){
            if(this.formRe.validate()){
                await UserService.setRechazar(this.formRechazo).then(
                    (response) => {
                        if(response.data.success){
                            this.dialog = false;
                            this.$toast.success(response.data.message);
                            location.reload();
                        }else{
                            this.$toast.error(response.data.message);
                        }
                    }
                );
            }
        },
      close() {
        this.$router.push({name: 'notarios'}).catch(() => {
        });
      },
    },
});
