
import Vue from 'vue';
import {mapGetters} from "vuex";
import {Solicitud} from '@/models/Solicitud';
import rules from "@/mixins/rules";
import CardAdquiriente from '@/components/Isabi/CardAdquiriente.vue';
import CardEnajenante from '@/components/Isabi/CardEnajenante.vue';
import {Enajenante} from "@/models/Enajenante";
import { Adquiriente } from '@/models/Adquiriente';
import SolicitudService from '@/services/SolicitudService';
import {VForm} from '@/types/formvalidate';
// @ts-ignore
import EventBus from "@/plugins/event-bus";
import SimService from "@/services/SimService";
import {CatalogoService} from "@/services/CatalogoService";
import DiasService from "@/services/DiaService";
import CustomFileIsabi from "@/components/Common/CustomFileIsabi.vue";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import moment from 'moment';
import moment2 from 'moment-business-days';
import UserService from "@/services/UserService";
import numeric from '@/directives/numeric';
import CustomFile from "@/components/Common/CustomFile.vue";
import any = jasmine.any;

export default Vue.extend({
  name: 'FormDidsaReadOnly',
  mixins: [rules],
  components: {
    CustomFile

  },
  data: () => ({
    serviceUma: new CatalogoService('catalogos/uma'),
    serviceEstablecimiento: new CatalogoService('catalogos/establecimiento'),
    valor_uma: 0,
    menu1: false,
    sameName: false,
    formSolicitud:new Solicitud(),
    didsaId:'',
    editar:false,
    cambio:false,
    documentos:false,
    userEstablecimiento:[],
    periodos: [],
    formRechazo: {
      id: '',
      motivo_rechazo: '',
    },
    datos:[],
    dialog: false,
  }),
  watch: {

  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm
    },
    formRe(): VForm {
      return this.$refs.formRechazo as VForm;
    },
    ...mapGetters({
      usuario: 'usuario', role: 'role', id: 'id', mini: 'sidebar'
    }),
  },
  mounted() {
    this.didsaId = this.$route.params.didsaId;
    this.editar = Boolean(this.$route.params.editar);
    this.getData();
    this.getEstablecimiento();
  },
  methods: {
    handleFileUploadSuccess(data: any) {
      // type FormUserKey = keyof typeof this.formUser;
      const typeFile = data.typeFile;
      const message = data.message;
      //@ts-ignore
      this.formUser[typeFile] = null;
      this.$toast.success(message);
    },
    handleFileUploadError (data: any){
      const message = data.message;
      this.$toast.error(message);
    },
    async getEstablecimiento(){
      const [establecimientos] =  await Promise.all([
        this.serviceEstablecimiento.getAll(),
      ]);
      if(establecimientos.data.data.user_establecimiento.length != 0){
        //@ts-ignore
        establecimientos.data.data.user_establecimiento.forEach(item => {
          //@ts-ignore
          this.userEstablecimiento.push(item.establecimiento);
        })
      }
    },
    setdatos(){
      //@ts-ignore
      this.datos = this.userEstablecimiento.filter(dt => dt.id == this.formSolicitud.establecimiento_id);//@ts-ignore
      this.formSolicitud.nombre_establecimiento = this.datos[0].nombre;//@ts-ignore
      this.formSolicitud.calle = this.datos[0].calle;//@ts-ignore
      this.formSolicitud.numExterior =  this.datos[0].numero_exterior;//@ts-ignore
      this.formSolicitud.numInterior =  this.datos[0].numero_interior;//@ts-ignore
      this.formSolicitud.codigoPostal =  this.datos[0].codigo_postal;//@ts-ignore
      this.formSolicitud.licencia = this.datos[0].licencia;//@ts-ignore
      this.formSolicitud.colonia =  this.datos[0].colonia;//@ts-ignore
      this.formSolicitud.localidad =  this.datos[0].localidad;//@ts-ignore
      this.formSolicitud.municipio =  this.datos[0].municipio;//@ts-ignore
      this.formSolicitud.estado =  this.datos[0].entidad;//@ts-ignore
      this.formSolicitud.establecimiento_id = this.datos[0].id;//@ts-ignore
      this.formSolicitud.id_establecimiento_api = this.datos[0].id_establecimiento_api;
    },
    async getData() {
      await SolicitudService.getById(this.didsaId).then(
          (response) => {
            if(response.status === 200){
              this.formSolicitud.establecimiento_id = response.data.establecimiento.id;
              this.formSolicitud.rfc = response.data.contribuyente.rfc;
              this.formSolicitud.licencia = response.data.establecimiento.licencia;
              this.formSolicitud.id_establecimiento_api = response.data.establecimiento.id_establecimiento_api;
              this.formSolicitud.representante = response.data.representante;
              this.formSolicitud.nombre_persona = response.data.contribuyente.nombre;
              this.formSolicitud.nombre_establecimiento = response.data.establecimiento.nombre;
              this.formSolicitud.calle = response.data.establecimiento.calle;
              this.formSolicitud.numExterior = response.data.establecimiento.numero_exterior;
              this.formSolicitud.numInterior = response.data.establecimiento.numero_interior;
              this.formSolicitud.codigoPostal = response.data.establecimiento.codigo_postal;
              this.formSolicitud.colonia = response.data.establecimiento.colonia;
              this.formSolicitud.localidad = response.data.establecimiento.localidad;
              this.formSolicitud.municipio = response.data.establecimiento.municipio;
              this.formSolicitud.estado = response.data.establecimiento.entidad;
              this.formSolicitud.calculo.numHabitaciones = response.data.calculo.numHabitaciones;
              this.formSolicitud.calculo.numHabitacionesOcupadas = response.data.calculo.numHabitacionesOcupadas;
              this.formSolicitud.calculo.porcentaje_Ocupacion = response.data.calculo.porcentaje_Ocupacion;
              this.formSolicitud.calculo.uma = response.data.calculo.derecho;
              this.formSolicitud.calculo.derecho_pagar = response.data.calculo.derecho_pagar;
              this.formSolicitud.estatus_id = response.data.estatus_id;
              this.formSolicitud.motivo_rechazo = response.data.motivo_rechazo;
              this.formSolicitud.user_id = response.data.usuario_id;
              this.formSolicitud.documento = response.data.archivos[0];
              this.formSolicitud.operacion = 1;
              if(response.data.archivos.length > 0){
                console.log(this.formSolicitud.documento);
                this.documentos = true;
              }

              //@ts-ignore
              var index = response.data.periodo.indexOf(",");
              //@ts-ignore
              this.formSolicitud.periodo = [response.data.periodo.substring(0,index),response.data.periodo.substring(index + 1)];

            }
          }
      );
    },
    close() {
      if(this.role == 'directoraMunicipio'){
        this.$router.push({name: 'solicitudesDidsa'}).catch(() => {
        });
      }else{
        this.$router.push({name: 'didsa'}).catch(() => {
        });
      }
    },
    // @ts-ignore
    async Validar(){
      await SolicitudService.ValidarSoli(parseInt(this.didsaId)).then(
          (response) => {
            if(response.data.success){
              this.$toast.success(response.data.message);
            }else{
              this.$toast.error(response.data.message);
            }
            setTimeout(() => {
              location.reload();
            }, 2000)
          }
      );
    },
    // @ts-ignore
    async RechazarDialog(){
      this.formRechazo.id = this.didsaId;
      this.dialog = true;
    },
    // @ts-ignore
    async RechazarEnvio(){
      if(this.formRe.validate()){
        await SolicitudService.RechazarSoli(this.formRechazo).then(
            (response) => {
              if(response.data.success){
                this.dialog = false;
                this.$toast.success(response.data.message);
              }else{
                this.$toast.error(response.data.message);
              }
              setTimeout(() => {
                location.reload();
              }, 2000)
            }

        );
      }
    },
    verificarCambio(){
      this.formSolicitud.periodo = this.periodos;
      this.periodos = [];
      if (this.documentos == true){
        this.cambio = false;
        console.log(this.cambio);
      }else{
        this.cambio = true;
        console.log(this.cambio);
      }

    },
    async calculoPorcentaje () {
      if (this.form.validate()) {
        if (this.formSolicitud.periodo == null) {
          this.$toast.warning("Debe seleccionar la fecha de inicio y fin de periodo");
          return;
        }
        if (this.formSolicitud.calculo.numHabitaciones == 0) {
          this.$toast.warning("Debe proporcionar el numero de habitaciones del establecimiento");
          return;
        }
        if (this.formSolicitud.calculo.numHabitacionesOcupadas == 0) {
          this.$toast.warning("Debe proporcionar el numero de habitaciones del establecimiento ocupadas durante el periodo");
          return;
        }
        //@ts-ignore
        const inicioPeriodo = moment(this.formSolicitud.periodo[0]);
        //@ts-ignore
        const finalPeriodo = moment(this.formSolicitud.periodo[1]);
        //@ts-ignore
        const fecha1 = new Date(this.formSolicitud.periodo[0])
        //@ts-ignore
        var fechaActual = fecha1.getFullYear();
        let dato = {search: fechaActual}
        const [umaRes] = await Promise.all([
          this.serviceUma.getAll(dato),
        ]);
        //Umas
        if (umaRes.data.length > 0) {
          this.valor_uma = umaRes.data[0].valor_diario;
        }
        //no hay uma vigente
        else {
          this.$toast.warning("No hay registro de uma vigente");
        }
        //@ts-ignore
        const porcentage = this.formSolicitud.numHabitacionesOcupadas / (this.formSolicitud.numHabitaciones * finalPeriodo.diff(inicioPeriodo, 'days'));
        //@ts-ignore
        this.formSolicitud.porcentaje_Ocupacion = (porcentage * 100).toFixed(2);
        var umaVal = this.valor_uma * .15;
        //@ts-ignore
        this.formSolicitud.uma = umaVal.toFixed(2);
        //@ts-ignore
        this.formSolicitud.derecho_pagar = (this.formSolicitud.uma * this.formSolicitud.numHabitacionesOcupadas).toFixed(2);
        this.cambio = false;
      }else{
        this.$toast.warning("Faltan datos por rellenar");
        return;
      }
    },
    async update() {
      if (this.form.validate() && !this.cambio) {
        if(this.formSolicitud.periodo.length < 2){
          this.$toast.warning("Falta proporcionar la segunda fecha del periodo");
          return;
        }
        let data_pre = null;
        data_pre = await SolicitudService.update(this.didsaId, this.formSolicitud);
        let {data} = data_pre;
        if (data.success) {
          this.$toast.success(data.message);
          this.$router.push({name: 'didsa'}).catch(() => {
          });
        } else {
          this.$toast.warning(data.message);
        }
      }else{
        this.$toast.warning("Faltan datos por rellenar");
        return;
      }
    },
  },
})
