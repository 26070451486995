import Json from '@/types/json'
import numeric from "@/directives/numeric";
interface _FileDocument {
    code: string,
    file: string,
    binary: string,
    data: any
}

class FileDocument implements _FileDocument {
    code: string
    file: string
    binary: string
    data: any

    constructor() {
        this.code = '';
        this.file = '';
        this.binary = '';
        this.data = null;
    }
}

interface _Usuario {
    id: number | null
    grado_academico: string
    nombre: string
    primer_apellido: string
    segundo_apellido: string
    rfc: string
    curp: string
    telefono: string
    notaria_id: number | null
    perfil_completo: number
    status: number
    email: string
    establecimineto_id: number | null
    numero_establecimineto: number
    nombre_establecimineto: string
    direccion_establecimineto: string
    localidad_establecimineto: string
    municipio_establecimineto: string
    entidad_establecimineto: string
    linea_naviera: number
    direccion_naviera: string
    buque: number
    poblacion: string
    documento_binary: any
    motivo_rechazo: string
    lineaNaviera_id: number | null
    domicilio: string
    user_puerto: any
    patente: FileDocument
}

class Usuario implements _Usuario{
    id = null
    grado_academico = ''
    nombre = ''
    primer_apellido = ''
    segundo_apellido = ''
    rfc = ""
    curp = ""
    telefono = ""
    notaria_id = null
    perfil_completo = 0
    status = 0
    email = ""
    numero_establecimineto = 0
    nombre_establecimineto = ""
    direccion_establecimineto = ""
    localidad_establecimineto = ""
    municipio_establecimineto = ""
    entidad_establecimineto = ""
    linea_naviera = 0
    direccion_naviera =""
    buque = 0
    documento_binary = ''
    poblacion = ""
    establecimineto_id = 0
    motivo_rechazo = ""
    lineaNaviera_id = 0
    domicilio= ""
    user_puerto = []
    patente = new FileDocument()
    setData(data: Json){

    }
}

export {_Usuario,Usuario}