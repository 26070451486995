
import Vue from "vue";
import RULES from "@/mixins/rules";
import SatService from "@/services/SatService";
import {mapGetters} from "vuex";
import any = jasmine.any;

export default Vue.extend({
  name: 'FormSeal',
  mixins: [RULES],
  props: ['select','ruta'],
  data: () => ({
    dialog: false,
    showPassword: false,
    latitude: '',
    longitude: '',
    formSat: {
      key: '',
      cer: '',
      password: ''
    },
  }),
  mounted() {

    this.getPosition()
  },
  computed: {
    ...mapGetters({
      usuario: 'usuario', modules: 'modules', email: 'email', status: 'status', role:'role'
    })
  },
  methods: {
    close() {
      this.$router.push({name: this.ruta}).catch(() => {
      });
    },
    getPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) =>{
          // @ts-ignore
          this.latitude = position.coords['latitude'];
          // @ts-ignore
          this.longitude = position.coords['longitude'];

        });
      }
    },
    async save() {
      let data = null;

      const formData = new FormData();

        formData.append('document-cer', this.formSat.cer);
        formData.append('document-key', this.formSat.key);
        formData.append('password', this.formSat.password);
        formData.append('role', this.role);
        formData.append('latitude', this.latitude);
        formData.append('longitude', this.longitude);

        // @ts-ignore
        this.select.forEach((value) => {
          formData.append("selected[]", value.id);
        });

        data = await SatService.create(formData)

        if (data['success']) {
          this.$toast.success(data['message'], {
            // @ts-ignore
            duration: 3000
          });
          setTimeout(() => {
            window.location.replace('/admin/'+ this.ruta);
          }, 3000); // Esperar 5 segundos antes de redirigir
        }else{
          this.$toast.error(data['message']);
        }
    }
  }
});
